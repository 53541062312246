.equipment-page-background {
    height: 1027px;
    width: 100%;
    position: absolute;
    top: 0;
    background-image: url("../images/shapes/background.svg");
    background-size: cover;
    background-position: center bottom;
}

.equipment-page-border {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}

@include media-breakpoint-up(lg) {
    .machinery-row {
        margin-bottom: 100px;
    }
}