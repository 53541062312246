.video {
    max-width: 100%;
}

.testimonials-background {
    height: 1160px;
    width: 100%;
    position: absolute;
    top: -50%;
    z-index: -1;
    background-image: url("../images/shapes/background.svg");
    background-size: cover;
    background-position: center top;
}

.testimonials-image {
    position: relative;
    text-align: center;
    margin-bottom: 100px;

    .secondary-image {
        position: absolute;
        bottom: -100px;
        right: 0;
    }
}