.header-background {
    height: 1027px;
    width: 100%;
    position: absolute;
    z-index: -1;
    background-image: url("../images/shapes/background.svg");
    background-size: cover;
    background-position: center bottom;
}

.header-padding {
    padding-top: 30px;
    padding-bottom: 145px;
}

.navbar {
    margin-bottom: 100px;

    .nav-item {

        .underline {
            position: relative;
            top: -6px;
            background-color: $primary;
            height: 2px;
            width: 0;
            transition: width 0.4s;
        }

        &.active .underline {
            width: 100px;
        }
    }
}

@include media-breakpoint-up(lg) {

    .navbar {
        .nav-item {
            .underline {
                margin: auto;
            }
    
            &:hover .underline,
            &:active .underline,
            &.active .underline {
                width: 40%;
            }
        }
    }
}