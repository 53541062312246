// Bootstrap variable overrides
$enable-responsive-font-sizes: true;

$primary: #f6b919;
$light: #f0efea;
$dark: #363026;

$theme-colors: (
  "primary": $primary,
  "light": $light,
  "dark": $dark,
);

$body-bg: $light;
$body-color: #6e6e6e;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$border-radius-lg: 0.5rem;

$font-family-sans-serif: "Brandon", sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-root: 1rem;
$font-size-base: 1.25rem;

$font-weight-base: 300;

$line-height-base: 1.5;

$h1-font-size: $font-size-root * 4.375;
$h2-font-size: $font-size-root * 3;
$h3-font-size: $font-size-root * 2;
$h4-font-size: $font-size-root * 1.625;
$h5-font-size: $font-size-root * 1.25;
$h6-font-size: $font-size-root * 1.125;

$headings-font-weight: 700;
$headings-color: $dark;

$small-font-size: .75em;

$input-btn-padding-y: 0.75rem;
$input-btn-font-size: $h6-font-size;

$nav-link-font-size: $h6-font-size;
$nav-link-font-weight: 400;

$navbar-nav-link-padding-x: 1rem;
$navbar-dark-color: $light;
$navbar-light-color: $dark;

$accordion-padding-y: 1.75rem;
$accordion-padding-x: 1.75rem;
$accordion-color: $dark;
$accordion-bg: white;
$accordion-border-radius: $border-radius-lg;
$accordion-icon-color: $primary;