body {
    overflow-x: hidden;
}

.spaced {
    letter-spacing: 5px;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tiny {
    font-size: 12px;
    letter-spacing: 1.38px;
}

.fw-semi-bold {
    font-weight: 500;
}

.z-index-1 {
    z-index: 1;
}

.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section-margin {
    margin-bottom: 100px;
}

@include media-breakpoint-down(md) {

    .section-padding {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}