.service-agent {
    max-width: 320px;
    background-image: url("../images/resources/agent-background.svg");
    background-size: cover;
    background-position: center;
    padding: 64px 30px 22px;
}

.agent-list-background {
    height: 1027px;
    width: 100%;
    position: absolute;
    top: -90%;
    z-index: -1;
    background-image: url("../images/shapes/background.svg");
    background-size: cover;
    background-position: center top;
}