footer {

    .footer-stamp {
        position: absolute;
        top: -37px;
        right: 10%;
        border: 3px solid $primary;
    }

    .nav-link {
        font-weight: $font-weight-light;
        color: $light;
    }

    .nav-link:hover {
        color: $primary;
    }
}