@font-face {
    font-family: "Brandon";
    src: url('../fonts/Brandon_med.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brandon";
    src: url('../fonts/Brandon_reg.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Brandon";
    src: url('../fonts/Brandon_bld.otf') format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Brandon";
    src: url('../fonts/Brandon_blk.otf') format("opentype");
    font-weight: 700;
    font-style: normal;
}
  