.header-equipment {
    background-image: url("../images/equipment/our_equipment.jpg");
    background-size: cover;
    background-position: center;
}

.equipment-list {
    padding-top: 60px;
    padding-bottom: 60px;
}

@include media-breakpoint-up(lg) {
    .equipment-info {
        min-height: 240px;
    }
}

.featured-equipment {
    padding-top: 190px;
    padding-bottom: 110px;
}

@include media-breakpoint-down(lg) {

    .featured-equipment {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}